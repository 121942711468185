<template>
  <div class="tab-bar">
    <router-link :to="loginType==1?'/index':'/baseAdmin/index'" class="tab-bar-link index" active-class="active">首页</router-link>
    <router-link v-if="loginType==1" to="/reservation" class="tab-bar-link reservation" active-class="active">预约</router-link>
    <router-link to="/userData" class="tab-bar-link my" active-class="active">我的</router-link>
  </div>
</template>
<script>

export default {
  name: 'tab-bar',
  data(){
    return {
      loginType: '',     //1用户端 2基地端
     }
  },
  mounted() {
    this.loginType = localStorage.getItem('loginType');
  }
}
</script>

<style lang="scss" scoped>
.tab-bar{
  width: 100%;
  height: 96px;
  background-color: #fff;
  border-top: 1px solid #EEE;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
}
.tab-bar-link{
  display: block;
  flex: 1;
  padding-top: 56px;
  font-size: 20px;
  font-weight: 500;
  color: #BEBEBE;
  text-align: center;
  float: left;
  &.active{
    color: #D63A29;
  }
  &.index{
    background: url(~@/assets/img/tab1_1.png) no-repeat center 10px;
    background-size: 42px;
    &.active{
      background: url(~@/assets/img/tab1_2.png) no-repeat center 10px;
      background-size: 42px;
    }
  }
  &.reservation{
    background: url(~@/assets/img/tab2_1.png) no-repeat center 6px;
    background-size: 48px;
    &.active{
      background: url(~@/assets/img/tab2_2.png) no-repeat center 6px;
      background-size: 48px;
    }
  }
  &.my{
    background: url(~@/assets/img/tab3_1.png) no-repeat center 6px;
    background-size: 48px;
    &.active{
      background: url(~@/assets/img/tab3_2.png) no-repeat center 6px;
      background-size: 48px;
    }
  }
}
</style>