import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: HomeView,
    children: [
      //登录
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login.vue'),
        meta:{
          footState:true,
        }
      },
      {
      path: '/baseAdmin/index',
      name: 'baseAdmin/index',
      component: () => import('../views/baseAdmin/index.vue'),
      meta:{
        title:'首页',
      }
     },
     {
      path: '/baseAdmin/detail',
      name: 'baseAdmin/detail',
      component: () => import('../views/baseAdmin/detail.vue'),
     },
     {
      path: '/baseAdmin/scan',
      name: 'baseAdmin/scan',
      component: () => import('../views/baseAdmin/scan.vue'),
      meta:{
        footState:true,
      }
     },
     {
      path: '/reservation',
      name: 'reservation',
      component: () => import('../views/reservation.vue'),
      
     },
     {
      path: '/userData',
      name: 'userData',
      component: () => import('../views/userData.vue'),
      meta:{
        title:'我的',
      }
     },
      // //注册
      // {
      //   path: '/register',
      //   name: 'Register',
      //   component: () => import('../views/login1.vue'),
      // },
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index.vue'),
      },
      //我的
      {
        path: '/my',
        name: 'My',
        component: () => import('../views/my.vue'),
      },
      //我的预约
      {
        path: '/myYuYue',
        name: 'MyYuYue',
        component: () => import('../views/myYuYue.vue'),
      },
      //基地详情
      {
        path: '/baseInfo',
        name: 'BaseInfo',
        component: () => import('../views/baseInfo.vue'),
        meta:{
          footState:true,
        }
      },
      //视频播放
      {
        path: '/videoPlay',
        name: 'videoPlay',
        component: () => import('../views/videoPlay.vue'),
        meta:{
          footState:true,
        }
      },
      //评分列表
      {
        path: '/rate/list',
        name: 'rate/list',
        component: () => import('../views/rate/list.vue'),
        meta:{
          footState:true,
        }
      },
       //评分详情
       {
        path: '/rate/detail',
        name: 'rate/detail',
        component: () => import('../views/rate/detail.vue'),
        meta:{
          footState:true,
        }
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
